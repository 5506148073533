var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("section", [
    _c("div", [
      _vm.isLayoutList
        ? _c("div", { staticClass: "multi-header has-bottom-margin" }, [
            _c(
              "div",
              {
                staticClass:
                  "title-with-edit is-flex is-align-content-center is-align-items-flex-start",
              },
              [
                _c("h2", { staticClass: "subtitle is-4 mr-2" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "admin.component.reseller.detail.resellerInfo.title"
                        )
                      ) +
                      " "
                  ),
                ]),
                _vm.$ability.can(
                  _vm.$permActions.UPDATE,
                  _vm.$permSubjects.ADMIN_RESELLER
                )
                  ? _c(
                      "b-button",
                      {
                        attrs: {
                          "icon-left": _vm.isEditing
                            ? "times-circle"
                            : "pencil-alt",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.toggleEditing()
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.isEditing
                                ? _vm.$t("generals.cancel_edit")
                                : ""
                            )
                        ),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ])
        : _vm._e(),
      _c("div", { staticClass: "columns info-fields" }, [
        _c(
          "div",
          { staticClass: "column px-0-mobile py-0-mobile" },
          [
            _c(
              "b-field",
              {
                staticClass: "is-flex mb-2",
                attrs: {
                  horizontal: "",
                  label: _vm.$t(
                    "admin.component.reseller.detail.resellerInfo.id"
                  ),
                },
              },
              [_c("p", [_vm._v(" " + _vm._s(_vm.reseller.id) + " ")])]
            ),
            _c(
              "b-field",
              {
                staticClass: "is-flex mb-2",
                attrs: {
                  horizontal: "",
                  label: _vm.$t(
                    "admin.component.reseller.createResellerModal.name"
                  ),
                  type: {
                    "is-danger": _vm.errors.has(
                      _vm.$t(
                        "admin.component.reseller.createResellerModal.name"
                      )
                    ),
                  },
                  message: _vm.errors.first(
                    _vm.$t("admin.component.reseller.createResellerModal.name")
                  ),
                },
              },
              [
                !_vm.isEditing
                  ? _c("p", [_vm._v(" " + _vm._s(_vm.reseller.name) + " ")])
                  : _c("b-input", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'",
                        },
                      ],
                      attrs: {
                        placeholder: _vm.$t(
                          "admin.component.reseller.createResellerModal.name"
                        ),
                        type: "text",
                        minlength: "5",
                        name: _vm.$t(
                          "admin.component.reseller.createResellerModal.name"
                        ),
                      },
                      model: {
                        value: _vm.resellerName,
                        callback: function ($$v) {
                          _vm.resellerName = $$v
                        },
                        expression: "resellerName",
                      },
                    }),
              ],
              1
            ),
            _c(
              "b-field",
              {
                staticClass: "is-flex mb-2",
                attrs: {
                  horizontal: "",
                  label: _vm.$t(
                    "admin.component.reseller.detail.resellerInfo.numberOfDepartments"
                  ),
                },
              },
              [
                _vm.reseller.departments
                  ? _c("p", [
                      _vm._v(
                        " " + _vm._s(_vm.reseller.departments.length) + " "
                      ),
                    ])
                  : _c("p", [_vm._v("-")]),
              ]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "column px-0-mobile py-0-mobile" },
          [
            _c(
              "b-field",
              {
                staticClass: "is-flex mb-2",
                attrs: {
                  horizontal: "",
                  label: _vm.$t(
                    "admin.component.reseller.detail.resellerInfo.numberOfUsers"
                  ),
                },
              },
              [
                _vm.reseller.members
                  ? _c("p", [
                      _vm._v(" " + _vm._s(_vm.reseller.members.length) + " "),
                    ])
                  : _c("p", [_vm._v("-")]),
              ]
            ),
            _c(
              "b-field",
              {
                staticClass: "is-flex mb-2",
                attrs: {
                  horizontal: "",
                  label: _vm.$t(
                    "admin.component.reseller.detail.resellerInfo.numberOfDevices"
                  ),
                },
              },
              [
                _vm.reseller.gateways
                  ? _c("p", [
                      _vm._v(" " + _vm._s(_vm.reseller.gateways.length) + " "),
                    ])
                  : _c("p", [_vm._v("-")]),
              ]
            ),
            _c(
              "b-field",
              {
                staticClass: "is-flex mb-2",
                attrs: {
                  horizontal: "",
                  label: _vm.$t(
                    "admin.component.reseller.detail.resellerInfo.numberOfLoggers"
                  ),
                },
              },
              [
                _vm.reseller.loggers
                  ? _c("p", [
                      _vm._v(" " + _vm._s(_vm.reseller.loggers.length) + " "),
                    ])
                  : _c("p", [_vm._v("-")]),
              ]
            ),
          ],
          1
        ),
      ]),
      _vm.isEditing
        ? _c(
            "div",
            { staticClass: "field is-grouped is-grouped-right mt-3" },
            [
              _c(
                "b-button",
                {
                  staticClass: "mr-3",
                  on: {
                    click: function ($event) {
                      return _vm.toggleEditing()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("generals.cancel_edit")))]
              ),
              _c(
                "b-button",
                {
                  attrs: { type: "is-primary" },
                  on: {
                    click: function ($event) {
                      return _vm.updateReseller()
                    },
                  },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "admin.component.reseller.detail.resellerInfo.update"
                      )
                    )
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }