var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm.isLoading
        ? _c(
            "b-loading",
            {
              staticClass: "is-flex-direction-column",
              attrs: {
                "is-full-page": true,
                active: _vm.isLoading,
                "can-cancel": false,
              },
              on: {
                "update:active": function ($event) {
                  _vm.isLoading = $event
                },
              },
            },
            [
              _c("p", { staticClass: "pb-3" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("component.location.detail.msg.file_download_info")
                    ) +
                    " "
                ),
              ]),
              _c("b-icon", {
                attrs: {
                  pack: "fas",
                  icon: "spinner",
                  size: "is-large",
                  "custom-class": "fa-pulse",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        {
          staticClass:
            "title-with-edit is-flex is-align-content-center is-align-items-flex-start",
        },
        [
          _c("h2", { staticClass: "subtitle is-4 mb-5 mr-2" }, [
            _vm._v(" " + _vm._s(_vm.$t("loggers.logger_detail")) + " "),
          ]),
          _vm.$ability.can(_vm.$permActions.MANAGE, _vm.$permSubjects.SOURCE)
            ? [
                !_vm.isEditing
                  ? _c("b-button", {
                      attrs: { "icon-right": "pencil-alt" },
                      on: {
                        click: function ($event) {
                          return _vm.editLogger()
                        },
                      },
                    })
                  : _c(
                      "b-button",
                      {
                        attrs: { "icon-left": "times-circle" },
                        on: {
                          click: function ($event) {
                            return _vm.editLogger()
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("generals.cancel_edit")))]
                    ),
              ]
            : _vm._e(),
        ],
        2
      ),
      (_vm.source.activeService && !_vm.source.activeService.endTime) ||
      (_vm.source.activeService &&
        _vm.source.activeService.endTime &&
        _vm.source.activeService.endTime * 1000 > new Date().getTime())
        ? _c(
            "b-message",
            { attrs: { type: "is-info", "has-icon": "", icon: "cog" } },
            [
              _vm.source.activeService.endTime
                ? [
                    _c("p", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "component.logger.service.service_active_note",
                              {
                                from: _vm.dateTimeManager.formatTime(
                                  new Date(
                                    _vm.source.activeService.startTime * 1000
                                  )
                                ),
                                to: _vm.dateTimeManager.formatTime(
                                  new Date(
                                    _vm.source.activeService.endTime * 1000
                                  )
                                ),
                              }
                            )
                          ) +
                          " "
                      ),
                    ]),
                    _c(
                      "b-button",
                      {
                        staticClass: "mt-2",
                        attrs: { "icon-right": "pencil-alt", type: "is-info" },
                        on: {
                          click: function ($event) {
                            return _vm.editService()
                          },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t("component.logger.service.edit_service")
                          )
                        ),
                      ]
                    ),
                  ]
                : [
                    _c("p", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "component.logger.service.service_active_no_end_note",
                              {
                                from: _vm.dateTimeManager.formatTime(
                                  new Date(
                                    _vm.source.activeService.startTime * 1000
                                  )
                                ),
                              }
                            )
                          ) +
                          " "
                      ),
                    ]),
                    _c(
                      "b-button",
                      {
                        staticClass: "mt-2",
                        attrs: { "icon-right": "pencil-alt", type: "is-info" },
                        on: {
                          click: function ($event) {
                            return _vm.editService()
                          },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t("component.logger.service.edit_service")
                          )
                        ),
                      ]
                    ),
                  ],
            ],
            2
          )
        : _vm._e(),
      _c("div", { staticClass: "columns columns-info-form" }, [
        _c(
          "div",
          { staticClass: "column" },
          [
            _c(
              "b-field",
              {
                staticClass: "field-wrap",
                attrs: {
                  horizontal: "",
                  label: _vm.$t("loggers.name"),
                  type: {
                    "is-danger":
                      _vm.errors.has(_vm.$t("loggers.name")) || _vm.nameExists,
                  },
                  message: _vm.nameExists
                    ? _vm.$t("loggers.unique_name")
                    : _vm.errors.first(_vm.$t("loggers.name")),
                },
              },
              [
                !_vm.isEditing
                  ? _c("p", [
                      _vm._v(
                        " " + _vm._s(_vm.sourceName || _vm.source.name) + " "
                      ),
                    ])
                  : _c("b-input", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required|max:100",
                          expression: "'required|max:100'",
                        },
                      ],
                      attrs: {
                        type: "text",
                        name: _vm.$t("loggers.name"),
                        expanded: "",
                      },
                      on: { input: _vm.checkIfNameExists },
                      model: {
                        value: _vm.sourceName,
                        callback: function ($$v) {
                          _vm.sourceName = $$v
                        },
                        expression: "sourceName",
                      },
                    }),
              ],
              1
            ),
            _c(
              "b-field",
              { attrs: { horizontal: "", label: _vm.$t("loggers.state") } },
              [
                _vm.source.logger != null
                  ? [
                      _vm.source.logger.state == _vm.ServiceState.ERROR
                        ? _c(
                            "b-tag",
                            { attrs: { rounded: "", type: "is-danger" } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("loggers.logger_states.Error")
                                  ) +
                                  " "
                              ),
                            ]
                          )
                        : _vm.source.logger.state
                        ? _c(
                            "p",
                            {
                              class: {
                                "has-text-weight-bold has-text-danger":
                                  _vm.source.logger.state ==
                                  _vm.ServiceState.ERROR,
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "loggers.logger_states." +
                                        _vm.source.logger.state
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          )
                        : _vm.source.logger.state === null
                        ? _c("p", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("loggers.logger_states.UNKNOWN")
                                ) +
                                " "
                            ),
                          ])
                        : _c("p", [_vm._v(_vm._s(_vm.source.logger.state))]),
                    ]
                  : _c("p", [_vm._v("-")]),
              ],
              2
            ),
            _c(
              "b-field",
              {
                staticClass: "field-wrap",
                attrs: {
                  horizontal: "",
                  label: _vm.$t("loggers.description"),
                  type: {
                    "is-danger": _vm.errors.has(_vm.$t("loggers.description")),
                  },
                  message: _vm.errors.first(_vm.$t("loggers.description")),
                },
              },
              [
                !_vm.isEditing ||
                !_vm.$ability.can(
                  _vm.$permActions.MANAGE,
                  _vm.$permSubjects.SOURCE
                )
                  ? _c("p", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.sourceDescription ||
                              _vm.source.description ||
                              "-"
                          ) +
                          " "
                      ),
                    ])
                  : _c("b-input", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "max:255",
                          expression: "'max:255'",
                        },
                      ],
                      attrs: {
                        type: "text",
                        name: _vm.$t("loggers.description"),
                        expanded: "",
                      },
                      model: {
                        value: _vm.sourceDescription,
                        callback: function ($$v) {
                          _vm.sourceDescription = $$v
                        },
                        expression: "sourceDescription",
                      },
                    }),
              ],
              1
            ),
            _c(
              "b-field",
              {
                staticClass: "field-wrap",
                attrs: {
                  horizontal: "",
                  label: _vm.$t("loggers.serial_number"),
                },
              },
              [
                _c(
                  "p",
                  { staticClass: "is-flex is-align-items-center" },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.source.logger != null
                            ? _vm.source.logger.serialNumber
                            : ""
                        ) +
                        " "
                    ),
                    _vm.source.logger && !_vm.source.logger.active
                      ? _c(
                          "b-tooltip",
                          {
                            attrs: {
                              label: _vm.$t(
                                "component.logger.overview.deactivated_logger"
                              ),
                              position: "is-right",
                              type: "is-dark",
                            },
                          },
                          [
                            _c("b-icon", {
                              staticClass: "ml-1",
                              staticStyle: { cursor: "pointer" },
                              attrs: {
                                icon: "exclamation-triangle",
                                type: "is-danger",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "b-field",
              { attrs: { horizontal: "", label: _vm.$t("loggers.group") } },
              [
                !_vm.isEditing ||
                !_vm.$ability.can(
                  _vm.$permActions.MANAGESOURCES,
                  _vm.$permSubjects.SOURCE_GROUP
                )
                  ? [
                      _vm.source.sourceGroup &&
                      _vm.selectedGroupName == _vm.source.sourceGroup.name
                        ? _c(
                            "router-link",
                            {
                              attrs: {
                                to: {
                                  name: "group_detail",
                                  params: {
                                    title: _vm.source.name,
                                    groupId: _vm.source.sourceGroup.id,
                                    departmentId:
                                      _vm.$route.params.departmentId,
                                    lang: _vm.$route.params.lang,
                                  },
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.source.sourceGroup.name))]
                          )
                        : _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.selectedGroupName ||
                                  _vm.$t("generals.unassigned")
                              )
                            ),
                          ]),
                    ]
                  : _c(
                      "b-select",
                      {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'",
                          },
                        ],
                        attrs: {
                          placeholder: _vm.$t(
                            "loggers.select_group_placeholder"
                          ),
                          expanded: "",
                          name: _vm.$t("loggers.group"),
                        },
                        on: { input: _vm.groupSelected },
                        model: {
                          value: _vm.selectedGroup,
                          callback: function ($$v) {
                            _vm.selectedGroup = $$v
                          },
                          expression: "selectedGroup",
                        },
                      },
                      _vm._l(_vm.sourceGroups, function (group) {
                        return _c(
                          "option",
                          { key: group.id, domProps: { value: group } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  group.name || _vm.$t("generals.unassigned")
                                ) +
                                " "
                            ),
                          ]
                        )
                      }),
                      0
                    ),
              ],
              2
            ),
            _c(
              "b-field",
              {
                attrs: { horizontal: "", label: _vm.$t("loggers.boundaries") },
              },
              [
                _c(
                  "div",
                  [
                    !_vm.canHaveBoundaries
                      ? _c(
                          "span",
                          [
                            _c("BoundaryInfo", {
                              staticClass: "is-italic has-text-grey",
                              attrs: {
                                boundaries: _vm.source.boundaries,
                                editOption: false,
                              },
                            }),
                            _c("p", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "component.location.create.msg.boundaries_disabled"
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                            _c(
                              "p",
                              {
                                staticClass:
                                  "is-italic has-text-grey line-height-2",
                              },
                              [
                                _vm.source.sourceGroup
                                  ? _c(
                                      "router-link",
                                      {
                                        attrs: {
                                          to: {
                                            name: "group_detail",
                                            query: { isBoundaryEdit: true },
                                            params: {
                                              title: _vm.source.name,
                                              groupId:
                                                _vm.source.sourceGroup.id,
                                              departmentId:
                                                _vm.$route.params.departmentId,
                                              lang: _vm.$route.params.lang,
                                            },
                                          },
                                          target: "_blank",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "component.location.create.msg.setBoundaries"
                                              )
                                            ) +
                                            " "
                                        ),
                                        _c("b-icon", {
                                          staticClass: "clickable",
                                          attrs: {
                                            icon: "fas fa-external-link-alt",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _c("BoundaryInfoWithEdit", {
                          ref: "boundariesComponent",
                          attrs: {
                            boundaries: _vm.getCopyOfBoundaries(),
                            hasEditPermissions: _vm.canHaveBoundaries
                              ? _vm.isEditing
                              : false,
                            eventTypes: _vm.eventTypes,
                          },
                          on: {
                            boundariesChanged: _vm.boundariesChanged,
                            validation: _vm.onBoundaryValidation,
                          },
                        }),
                  ],
                  1
                ),
              ]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "column" },
          [
            _vm.$featuresManager.isAvailable(_vm.$features.VIRTUAL_QUANTITIES)
              ? _c(
                  "b-field",
                  {
                    attrs: {
                      horizontal: "",
                      label: _vm.$t("loggers.virtual_quantities"),
                    },
                  },
                  [
                    !_vm.isEditing
                      ? _c(
                          "p",
                          [
                            _vm.selectedQuantities.length > 0
                              ? _vm._l(
                                  _vm.selectedQuantities,
                                  function (q, index) {
                                    return _c(
                                      "span",
                                      { key: index },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "loggers.quantity_types." +
                                                  q.key
                                              )
                                            ) +
                                            " "
                                        ),
                                        index !==
                                        _vm.selectedQuantities.length - 1
                                          ? [_vm._v(", ")]
                                          : _vm._e(),
                                      ],
                                      2
                                    )
                                  }
                                )
                              : _c("span", [_vm._v("-")]),
                          ],
                          2
                        )
                      : _c("CheckboxSelect", {
                          attrs: {
                            options: _vm.quantities,
                            selectedOptions: _vm.selectedQuantities,
                            langPath: "loggers.quantity_types.",
                            path: "key",
                          },
                          on: { entitySelected: _vm.quantityArrChanged },
                        }),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "b-field",
              {
                attrs: { horizontal: "", label: _vm.$t("loggers.visibility") },
              },
              [
                !_vm.isEditing
                  ? _c(
                      "b-tooltip",
                      {
                        attrs: {
                          label: _vm.source.visible
                            ? _vm.$t("component.logger.list.table.visible")
                            : _vm.$t("component.logger.list.table.hidden"),
                          position: "is-right",
                          type: "is-light",
                        },
                      },
                      [
                        _c("b-icon", {
                          attrs: {
                            icon: _vm.source.visible ? "eye" : "eye-slash",
                          },
                        }),
                      ],
                      1
                    )
                  : _c(
                      "b-select",
                      {
                        attrs: {
                          placeholder: _vm.$t("loggers.visibility"),
                          expanded: "",
                          name: _vm.$t("loggers.visibility"),
                          icon: "eye",
                        },
                        model: {
                          value: _vm.selectedVisibility,
                          callback: function ($$v) {
                            _vm.selectedVisibility = $$v
                          },
                          expression: "selectedVisibility",
                        },
                      },
                      [
                        _c("option", { domProps: { value: true } }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("loggers.visibility_state.visible")
                              ) +
                              " "
                          ),
                        ]),
                        _c("option", { domProps: { value: false } }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("loggers.visibility_state.hidden")
                              ) +
                              " "
                          ),
                        ]),
                      ]
                    ),
              ],
              1
            ),
            _c(
              "b-field",
              {
                attrs: { horizontal: "", label: _vm.$t("loggers.calibration") },
              },
              [
                _c(
                  "div",
                  { staticClass: "is-flex is-align-items-center" },
                  [
                    _vm.source.logger != null &&
                    _vm.source.logger.calibration != null
                      ? _c(
                          "b-tag",
                          {
                            attrs: {
                              rounded: "",
                              type: _vm.source.logger.calibration.status.type,
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  `calibration_status.${_vm.source.logger.calibration.status.name}`
                                )
                              )
                            ),
                          ]
                        )
                      : _c(
                          "b-tag",
                          {
                            attrs: {
                              type: _vm.CalibrationStatus.UNKNOWN.type,
                              rounded: "",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  `calibration_status.${_vm.CalibrationStatus.UNKNOWN.name}`
                                )
                              )
                            ),
                          ]
                        ),
                    _vm.$ability.can(
                      _vm.$permActions.READ,
                      _vm.$permSubjects.LOGGER
                    ) &&
                    _vm.hasCalibrationProtocolToDownload &&
                    !_vm.isDemo
                      ? _c(
                          "b-button",
                          {
                            staticClass: "ml-2",
                            attrs: {
                              size: "is-small",
                              type: "is-primary",
                              outlined: "",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.downloadCalibration()
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t("component.location.detail.download")
                              )
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.$ability.can(
                      _vm.$permActions.READ,
                      _vm.$permSubjects.LOGGER
                    ) &&
                    _vm.hasCalibrationProtocolToDownload &&
                    _vm.isDemo
                      ? _c(
                          "b-button",
                          {
                            staticClass: "ml-2",
                            attrs: {
                              size: "is-small",
                              type: "is-primary",
                              outlined: "",
                              tag: "a",
                              href: _vm.pdf.default,
                              download: "Calibration Sample",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t("component.location.detail.download")
                              )
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "b-field",
              {
                attrs: {
                  horizontal: "",
                  label: _vm.$t("loggers.calibration_date"),
                },
              },
              [
                _vm.source.logger != null &&
                _vm.source.logger.calibration != null
                  ? _c("p", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.dateTimeManager.formatTime(
                              new Date(
                                _vm.source.logger.calibration.calibratedAt *
                                  1000
                              ),
                              "UTC",
                              null,
                              ""
                            )
                          ) +
                          " "
                      ),
                    ])
                  : _c("p", [_vm._v("-")]),
              ]
            ),
            _c(
              "b-field",
              {
                attrs: {
                  horizontal: "",
                  label: _vm.$t("loggers.expiration_date"),
                },
              },
              [
                _vm.source.logger != null &&
                _vm.source.logger.calibration != null
                  ? _c("p", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.dateTimeManager.formatTime(
                              new Date(
                                _vm.source.logger.calibration.expiresAt * 1000
                              ),
                              "UTC",
                              null,
                              ""
                            )
                          ) +
                          " "
                      ),
                    ])
                  : _c("p", [_vm._v("-")]),
              ]
            ),
            _c(
              "b-field",
              { attrs: { horizontal: "", label: _vm.$t("loggers.battery") } },
              [
                _vm.source.logger != null && _vm.source.logger.battery != null
                  ? _c(
                      "p",
                      [
                        _vm._v(" " + _vm._s(_vm.source.logger.battery) + "% "),
                        _vm.source.logger.battery <= 15
                          ? _c("b-icon", {
                              staticClass: "is-size-5",
                              attrs: {
                                icon: "exclamation-triangle",
                                type: "is-primary",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  : _c("p", [_vm._v("-")]),
              ]
            ),
            _c(
              "b-field",
              {
                attrs: {
                  horizontal: "",
                  label: _vm.$t("loggers.connection_status"),
                },
              },
              [
                _vm.source.logger != null
                  ? _c(
                      "p",
                      [
                        _c("b-icon", {
                          staticClass: "is-size-7",
                          attrs: {
                            type:
                              _vm.source.logger.isOnline == true
                                ? "is-success"
                                : "is-danger",
                            icon: "circle",
                          },
                        }),
                        _vm.source.logger.isOnline == true
                          ? _c("span", [
                              _vm._v(_vm._s(_vm.$t("generals.online"))),
                            ])
                          : _c("span", [
                              _vm._v(_vm._s(_vm.$t("generals.offline"))),
                            ]),
                        _vm.source.pausedMeasurement
                          ? _c(
                              "span",
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "has-text-primary has-text-weight-bold pl-3",
                                  },
                                  [_vm._v(_vm._s(_vm.$t("loggers.paused")))]
                                ),
                                _c(
                                  "b-tooltip",
                                  {
                                    attrs: {
                                      label: _vm.$t("loggers.start_note"),
                                      position: "is-right",
                                    },
                                  },
                                  [
                                    _c("b-icon", {
                                      staticClass: "clickable",
                                      attrs: {
                                        icon: "fas fa-play-circle",
                                        type: "is-success",
                                      },
                                      nativeOn: {
                                        click: function ($event) {
                                          return _vm.changePausedMeasurementFlag()
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _c(
                              "b-tooltip",
                              {
                                attrs: {
                                  label: _vm.$t("loggers.pause_note"),
                                  position: "is-right",
                                },
                              },
                              [
                                _c("b-icon", {
                                  staticClass: "clickable",
                                  attrs: {
                                    icon: "fas fa-pause-circle",
                                    type: "is-danger",
                                  },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.changePausedMeasurementFlag()
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                      ],
                      1
                    )
                  : _c("p", [_vm._v("-")]),
              ]
            ),
            _c(
              "b-field",
              { attrs: { horizontal: "", label: _vm.$t("loggers.last_seen") } },
              [
                _vm.source.logger != null
                  ? _c("p", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.dateTimeManager.formatTime(
                              new Date(_vm.source.lastSeen * 1000)
                            )
                          ) +
                          " "
                      ),
                    ])
                  : _c("p", [_vm._v("-")]),
              ]
            ),
          ],
          1
        ),
      ]),
      _vm.hasChanges
        ? _c(
            "div",
            {
              staticClass:
                "has-text-centered is-size-4 has-text-primary has-text-weight-semibold",
            },
            [
              _c("p", [
                _vm._v(
                  _vm._s(_vm.$t("component.group.detail.table.unsaved_changes"))
                ),
              ]),
            ]
          )
        : _vm._e(),
      _vm.isEditing &&
      _vm.$ability.can(_vm.$permActions.MANAGE, _vm.$permSubjects.SOURCE)
        ? _c(
            "div",
            { staticClass: "has-text-right" },
            [
              _c(
                "b-button",
                {
                  attrs: { disabled: !_vm.hasChanges, type: "is-primary" },
                  on: {
                    "~click": function ($event) {
                      return _vm.validate()
                    },
                  },
                },
                [
                  _vm._v(
                    _vm._s(_vm.$t("component.location.detail.saveChanges_btn"))
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.$ability.can(_vm.$permActions.MANAGE, _vm.$permSubjects.SOURCE) &&
      _vm.$ability.can(_vm.$permActions.READ, _vm.$permSubjects.LOGGER)
        ? _c(
            "b-modal",
            {
              attrs: {
                active: _vm.isChangeLoggerModalActive,
                "has-modal-card": "",
                onCancel: _vm.closeChangeLoggerModal,
              },
              on: {
                "update:active": function ($event) {
                  _vm.isChangeLoggerModalActive = $event
                },
                "close-modal": _vm.closeChangeLoggerModal,
              },
            },
            [
              _c("ChangeLoggerModal", {
                attrs: {
                  source: _vm.source,
                  companyLoggers: _vm.availableCompanyLoggers,
                  modalIsReassigning: _vm.modalIsReassigning,
                },
                on: { modalClosed: _vm.closeChangeLoggerModal },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.createRuleWarningVisible
        ? _c("CreateRuleWarning", {
            attrs: { sourceIds: _vm.unasignedSourcesIds },
            on: { warningClosed: _vm.warningClosed },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }