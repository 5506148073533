var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("div", { staticClass: "mt-0 mx-4 mb-4 mx-0-desktop mb-5-desktop" }, [
        _c(
          "div",
          { staticClass: "is-flex is-justify-content-space-between" },
          [
            _c(
              "h1",
              {
                staticClass: "title is-3 is-spaced mb-0",
                staticStyle: { "word-wrap": "anywhere" },
              },
              [
                _vm.title
                  ? _c("span", [_vm._v(_vm._s(_vm.title))])
                  : _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.$t("component.public_dashboard.dashboard.title")
                        )
                      ),
                    ]),
              ]
            ),
            _vm.isAuthorizedDashboard
              ? _c(
                  "b-tooltip",
                  {
                    attrs: {
                      label: _vm.$t(
                        "component.public_dashboard.refreshSettings.title"
                      ),
                      position: "is-left",
                      type: "is-dark",
                    },
                  },
                  [
                    _c(
                      "b-button",
                      {
                        attrs: { type: "is-primary" },
                        nativeOn: {
                          click: function ($event) {
                            _vm.isOpenRefreshSettingsModal = true
                          },
                        },
                      },
                      [_c("b-icon", { attrs: { icon: "cog" } })],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
        _vm.dateTimeManager !== null
          ? _c("h4", { staticClass: "subtitle is-5 mt-2 mb-0" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "component.public_dashboard.refreshSettings.lastRefresh"
                    )
                  ) +
                  " " +
                  _vm._s(_vm.dateTimeManager.formatTime(_vm.lastRefresh)) +
                  " "
              ),
            ])
          : _vm._e(),
        _c("p", { staticClass: "is-italic" }, [
          _vm._v(
            _vm._s(_vm.$t("generals.used_timezone")) +
              ": " +
              _vm._s(_vm.selectedTimeZone)
          ),
        ]),
      ]),
      !_vm.isAuthorizedDashboard
        ? _c(
            "div",
            { staticClass: "box has-text-centered has-text-weight-bold" },
            [
              _c(
                "div",
                [
                  _c("b-icon", {
                    attrs: {
                      size: "is-large",
                      icon: "lock",
                      type: "is-primary",
                    },
                  }),
                ],
                1
              ),
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "component.public_dashboard.dashboard_detail.notAuthorized"
                    )
                  ) +
                  " "
              ),
            ]
          )
        : _vm.hasNoSources
        ? _c(
            "div",
            {
              staticClass:
                "box has-text-centered has-text-weight-bold has-text-primary",
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "component.public_dashboard.dashboard_detail.no_sources"
                    )
                  ) +
                  " "
              ),
            ]
          )
        : _c("div", { staticClass: "tile is-ancestor" }, [
            _c(
              "div",
              { staticClass: "tile is-vertical is-full-width" },
              [
                _c(
                  "div",
                  { staticClass: "tile is-parent" },
                  [
                    !_vm.isLoading.StatesComponent
                      ? _c("StatesComponent", {
                          staticClass: "tile is-child box py-4",
                          attrs: {
                            sourceGroups: _vm.dashboardData
                              ? _vm.dashboardData.sourceGroups
                              : [],
                            sourceGroupSources: _vm.sourceGroupSources,
                            selectedSourceGroupId: _vm.selectedSourceGroupId,
                            sources: _vm.dashboardData
                              ? _vm.dashboardData.sources
                              : [],
                            allSources: _vm.allSources,
                            allDevices: _vm.allDevices,
                            dateTimeManager: _vm.dateTimeManager,
                            byGroup: _vm.byGroup,
                          },
                          on: {
                            sourceGroupSelected: _vm.sourceGroupSelected,
                            allSelected: _vm.allSelected,
                          },
                        })
                      : _c(
                          "div",
                          { staticClass: "tile is-child box" },
                          [
                            _c("b-skeleton", {
                              attrs: { height: "90px", animated: true },
                            }),
                          ],
                          1
                        ),
                  ],
                  1
                ),
                _vm.dashboardData &&
                _vm.dashboardData.allowedComponents.includes(
                  _vm.PublicDashboardComponents.LAST_MEASUREMENTS
                )
                  ? _c(
                      "div",
                      { staticClass: "tile is-parent" },
                      [
                        _c("LastEventsComponent", {
                          staticClass: "tile is-child box",
                          attrs: {
                            sourceGroupSources:
                              _vm.byGroup === true
                                ? _vm.sourceGroupSources
                                : _vm.allSources,
                            sourceGroupEvents:
                              _vm.byGroup === true
                                ? _vm.sourceGroupEvents
                                : _vm.allSourceGroupEvents,
                            refreshLastEvents: _vm.refreshLastEvents,
                            componentIsLoading:
                              _vm.isLoading.LastEventsComponent,
                            showRefreshControls: false,
                            onPublicPage: true,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.dashboardData &&
                _vm.dashboardData.allowedComponents.includes(
                  _vm.PublicDashboardComponents.CHART
                )
                  ? _c(
                      "div",
                      { staticClass: "tile is-parent" },
                      [
                        _c("ChartComponent", {
                          staticClass: "tile is-child box",
                          attrs: {
                            otherServiceSources: _vm.otherServiceSources,
                            upTimeServiceSources: _vm.upTimeServiceSources,
                            chartData: _vm.otherSourcesChartData,
                            upTimeChartData: _vm.upTimeSourceschartData,
                            eventTypes: _vm.eventTypes,
                            chartHeight: _vm.chartHeight,
                            dayBeforeOption: _vm.selectedDayOption,
                            componentIsLoading: _vm.isLoading.ChartComponent,
                            onPublicPage: true,
                          },
                          on: {
                            dayOptionSelected: _vm.dayOptionSelected,
                            changeUserSetting: _vm.changeChartHeight,
                            eventHandler: _vm.eventHandler,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.dashboardData &&
                _vm.dashboardData.allowedComponents.includes(
                  _vm.PublicDashboardComponents.REPORTS
                )
                  ? [
                      _c(
                        "div",
                        { staticClass: "multi-header tile is-parent" },
                        [
                          _c("div", [
                            _c(
                              "h1",
                              {
                                staticClass:
                                  "title is-3 is-spaced has-text-centered-touch",
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("component.report.title")) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                          _c(
                            "div",
                            { staticClass: "is-flex-grow-2" },
                            [
                              !_vm.isLoading.Reports
                                ? _c("ReportConfigurationComponent", {
                                    attrs: {
                                      reportExportSetting:
                                        _vm.reportExportSetting,
                                      configuration: _vm.configuration,
                                      dateTimeManager: _vm.dateTimeManager,
                                      showEmpty: _vm.showEmpty,
                                      isPublic: _vm.dashboardData.isPublic,
                                      selectedDateRange: _vm.dateRange,
                                    },
                                    on: {
                                      applyConfiguration:
                                        _vm.applyConfiguration,
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "tile is-parent" },
                        [
                          !_vm.isLoading.Reports
                            ? _c("ReportsOverallChart", {
                                staticClass: "tile is-child box",
                                attrs: {
                                  reportRecords: _vm.reportRecordsForChart,
                                  eventTypes: _vm.eventTypes,
                                  sourceGroupSources: _vm.otherServiceSources,
                                  dateFilter: _vm.monthFilter,
                                  onPublicPage: true,
                                  chartHeight: _vm.chartHeight,
                                },
                                on: {
                                  axisRangeSelected: _vm.axisRangeSelected,
                                  changeUserSetting: _vm.changeChartHeight,
                                  eventHandler: _vm.eventHandler,
                                },
                              })
                            : _c(
                                "div",
                                { staticClass: "tile is-child box" },
                                [
                                  _c("b-skeleton", {
                                    attrs: { height: "90px", animated: true },
                                  }),
                                ],
                                1
                              ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "tile is-parent" },
                        [
                          _vm.axisRangesLoaded == true && !_vm.isLoading.Reports
                            ? _c("SourceTabs", {
                                staticClass: "tile is-child box",
                                attrs: {
                                  sourceGroupSources: _vm.otherServiceSources,
                                  sourceGroupStatistics:
                                    _vm.sourceGroupStatistics,
                                  reportRecords: _vm.reportRecordsForTable,
                                  allReportRecords: _vm.reportRecordsForChart,
                                  eventTypes: _vm.eventTypes,
                                  dateFilter: _vm.monthFilter,
                                  axisRanges: _vm.axisRanges,
                                  dateTimeManager: _vm.dateTimeManager,
                                  filterRecords: _vm.filterRecords,
                                  onPublicPage: true,
                                  configuration: _vm.configuration,
                                },
                                on: { eventHandler: _vm.eventHandler },
                              })
                            : _c(
                                "div",
                                { staticClass: "tile is-child box" },
                                [
                                  _c("b-skeleton", {
                                    attrs: { height: "90px", animated: true },
                                  }),
                                ],
                                1
                              ),
                        ],
                        1
                      ),
                    ]
                  : _vm._e(),
              ],
              2
            ),
          ]),
      _c(
        "b-modal",
        {
          attrs: {
            active: _vm.isOpenRefreshSettingsModal,
            "has-modal-card": "",
            onCancel: _vm.closeRefreshSettingsModal,
          },
          on: {
            "update:active": function ($event) {
              _vm.isOpenRefreshSettingsModal = $event
            },
          },
        },
        [
          _c("RefreshSettingsModal", {
            attrs: {
              isRefreshEnabled: _vm.refreshLastEvents,
              refreshInterval: _vm.refreshInterval,
            },
            on: {
              saveRefreshSettings: _vm.saveRefreshSettings,
              modalClosed: _vm.closeRefreshSettingsModal,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }