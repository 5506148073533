var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("section", [
    _c("div", { staticClass: "section-header-level" }, [
      _c(
        "div",
        { staticClass: "title-with-edit" },
        [
          _c("h2", { staticClass: "subtitle is-4 mr-2" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t("admin.component.company.detail.companyInfo.title")
                ) +
                " "
            ),
          ]),
          _vm.$ability.can(
            _vm.$permActions.UPDATE,
            _vm.$permSubjects.ADMIN_DEPARTMENTS
          )
            ? _c(
                "b-button",
                {
                  attrs: {
                    "icon-left": _vm.isEditing ? "times-circle" : "pencil-alt",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.editCompany()
                    },
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.isEditing ? _vm.$t("generals.cancel_edit") : ""
                      )
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      !_vm.isEditing
        ? _c(
            "div",
            [
              _c(
                "b-button",
                {
                  attrs: {
                    type: "is-primary is-hidden-mobile",
                    "icon-pack": "fa",
                    "icon-left": "sign-in-alt",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.loginIntoCompany()
                    },
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "admin.component.company.detail.companyInfo.loginIntoCompany"
                        )
                      ) +
                      " "
                  ),
                ]
              ),
              _c("b-button", {
                attrs: {
                  type: "is-primary is-hidden-tablet",
                  "icon-pack": "fa",
                  "icon-left": "sign-in-alt",
                },
                on: {
                  click: function ($event) {
                    return _vm.loginIntoCompany()
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ]),
    _c("div", { staticClass: "columns info-fields mb-0" }, [
      _c(
        "div",
        { staticClass: "column px-0-mobile py-0-mobile" },
        [
          _c(
            "b-field",
            {
              staticClass: "is-flex mb-2",
              attrs: {
                horizontal: "",
                label: _vm.$t(
                  "admin.component.company.detail.companyInfo.companyId"
                ),
              },
            },
            [_c("p", [_vm._v(" " + _vm._s(_vm.company.companyId) + " ")])]
          ),
          _c(
            "b-field",
            {
              staticClass: "is-flex mb-2",
              attrs: {
                horizontal: "",
                label: _vm.$t(
                  "admin.component.company.createCompanyModal.form.company"
                ),
                type: {
                  "is-danger": _vm.errors.has(
                    _vm.$t(
                      "admin.component.company.createCompanyModal.form.company"
                    )
                  ),
                },
                message: _vm.errors.first(
                  _vm.$t(
                    "admin.component.company.createCompanyModal.form.company"
                  )
                ),
              },
            },
            [
              !_vm.isEditing
                ? _c("p", [_vm._v(" " + _vm._s(_vm.company.companyName) + " ")])
                : _c("b-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required|max:50",
                        expression: "'required|max:50'",
                      },
                    ],
                    attrs: {
                      placeholder: _vm.$t(
                        "admin.component.company.createCompanyModal.form.companyName"
                      ),
                      type: "text",
                      name: _vm.$t(
                        "admin.component.company.createCompanyModal.form.company"
                      ),
                    },
                    model: {
                      value: _vm.companyName,
                      callback: function ($$v) {
                        _vm.companyName = $$v
                      },
                      expression: "companyName",
                    },
                  }),
            ],
            1
          ),
          _c(
            "b-field",
            {
              staticClass: "is-flex mb-2",
              attrs: {
                horizontal: "",
                label: _vm.$t(
                  "admin.component.company.detail.companyInfo.companyIco"
                ),
                type: {
                  "is-danger": _vm.errors.has(
                    _vm.$t(
                      "admin.component.company.detail.companyInfo.companyIco"
                    )
                  ),
                },
                message: _vm.errors.first(
                  _vm.$t(
                    "admin.component.company.detail.companyInfo.companyIco"
                  )
                ),
              },
            },
            [
              !_vm.isEditing
                ? _c("p", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.company.ico ? _vm.company.ico : "-") +
                        " "
                    ),
                  ])
                : _c("b-input", {
                    attrs: {
                      placeholder: _vm.$t(
                        "admin.component.company.createCompanyModal.form.companyIco"
                      ),
                      type: "text",
                      name: _vm.$t(
                        "admin.component.company.detail.companyInfo.companyIco"
                      ),
                    },
                    model: {
                      value: _vm.companyIco,
                      callback: function ($$v) {
                        _vm.companyIco = $$v
                      },
                      expression: "companyIco",
                    },
                  }),
            ],
            1
          ),
          _c(
            "b-field",
            {
              staticClass: "is-flex mb-2",
              attrs: {
                horizontal: "",
                label: _vm.$t(
                  "admin.component.company.detail.companyInfo.responsiblePerson"
                ),
                type: {
                  "is-danger": _vm.errors.has(
                    _vm.$t(
                      "admin.component.company.detail.companyInfo.responsiblePerson"
                    )
                  ),
                },
                message: _vm.errors.first(
                  _vm.$t(
                    "admin.component.company.detail.companyInfo.responsiblePerson"
                  )
                ),
              },
            },
            [
              !_vm.isEditing
                ? _c(
                    "p",
                    [
                      _vm.company.responsiblePerson
                        ? _c(
                            "router-link",
                            {
                              attrs: {
                                to: {
                                  name: "adminUserDetail",
                                  params: {
                                    userId:
                                      _vm.company.responsiblePerson.apiUserId,
                                    title: _vm.company.companyName,
                                    lang: _vm.$route.params.lang,
                                  },
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.company.responsiblePerson.username)
                              ),
                            ]
                          )
                        : _c("span", [_vm._v("-")]),
                    ],
                    1
                  )
                : _c("b-autocomplete", {
                    attrs: {
                      data: _vm.filteredUsers,
                      field: "username",
                      placeholder: _vm.$t(
                        "component.userAdministration.list.table.username"
                      ),
                      "icon-pack": "fas",
                      icon: "search",
                      clearable: "",
                      "open-on-focus": "",
                    },
                    on: { select: _vm.userNameChanged },
                    scopedSlots: _vm._u([
                      {
                        key: "empty",
                        fn: function () {
                          return [_vm._v(_vm._s(_vm.$t("generals.no_results")))]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm.selectedUserName,
                      callback: function ($$v) {
                        _vm.selectedUserName = $$v
                      },
                      expression: "selectedUserName",
                    },
                  }),
            ],
            1
          ),
          _c(
            "b-field",
            {
              staticClass: "is-flex mb-2",
              attrs: {
                horizontal: "",
                label: _vm.$t(
                  "admin.component.company.detail.companyInfo.since"
                ),
              },
            },
            [
              _c("p", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.dateTimeManager.formatTime(
                        new Date(_vm.company.createdAt * 1000),
                        null,
                        null,
                        null
                      )
                    ) +
                    " "
                ),
              ]),
            ]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "column px-0-mobile py-0-mobile" },
        [
          !_vm.isResellerAdmin
            ? _c(
                "b-field",
                {
                  staticClass: "is-flex mb-2 field-wrap",
                  attrs: {
                    horizontal: "",
                    label: _vm.$t(
                      "admin.component.company.detail.companyInfo.note"
                    ),
                    type: {
                      "is-danger": _vm.errors.has(
                        _vm.$t(
                          "admin.component.company.detail.companyInfo.note"
                        )
                      ),
                    },
                    message: _vm.errors.first(
                      _vm.$t("admin.component.company.detail.companyInfo.note")
                    ),
                  },
                },
                [
                  !_vm.isEditing
                    ? _c("p", [_vm._v(_vm._s(_vm.company.note))])
                    : _c("b-input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "max:255",
                            expression: "'max:255'",
                          },
                        ],
                        attrs: {
                          type: "textarea",
                          name: _vm.$t(
                            "admin.component.company.detail.companyInfo.note"
                          ),
                        },
                        model: {
                          value: _vm.companyNote,
                          callback: function ($$v) {
                            _vm.companyNote = $$v
                          },
                          expression: "companyNote",
                        },
                      }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-field",
            {
              staticClass: "is-flex mb-2",
              attrs: {
                horizontal: "",
                label: _vm.$t(
                  "admin.component.company.detail.companyInfo.numberOfUsers"
                ),
              },
            },
            [_c("p", [_vm._v(" " + _vm._s(_vm.company.userCount) + " ")])]
          ),
          _c(
            "b-field",
            {
              staticClass: "is-flex mb-2",
              attrs: {
                horizontal: "",
                label: _vm.$t(
                  "admin.component.company.detail.companyInfo.numberOfDevices"
                ),
              },
            },
            [_c("p", [_vm._v(" " + _vm._s(_vm.company.deviceCount) + " ")])]
          ),
          _c(
            "b-field",
            {
              staticClass: "is-flex",
              attrs: {
                horizontal: "",
                label: _vm.$t(
                  "admin.component.company.detail.companyInfo.numberOfLoggers"
                ),
              },
            },
            [_c("p", [_vm._v(" " + _vm._s(_vm.company.loggerCount) + " ")])]
          ),
        ],
        1
      ),
    ]),
    _vm.isEditing
      ? _c(
          "div",
          { staticClass: "field is-grouped is-grouped-right mt-3" },
          [
            _c(
              "b-button",
              {
                attrs: { type: "is-primary" },
                on: {
                  click: function ($event) {
                    return _vm.updateCompany()
                  },
                },
              },
              [
                _vm._v(
                  _vm._s(_vm.$t("admin.component.company.update.updateName"))
                ),
              ]
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }