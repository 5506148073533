var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c(
      "div",
      { staticClass: "columns is-multiline is-8-desktop status-cards" },
      [
        _c(
          "div",
          { staticClass: "column is-one-third-tablet is-2-widescreen" },
          [
            _c(
              "router-link",
              {
                staticClass: "box is-full-height p-0",
                class:
                  _vm.sources.length !==
                  _vm.sources.filter(
                    (x) => x.logger && x.logger.isOnline === true
                  ).length
                    ? "border-warning"
                    : "",
                attrs: {
                  to: {
                    name: "configuration_loggers",
                    params: {
                      lang: _vm.$route.params.lang,
                      departmentId: _vm.$route.params.departmentId,
                    },
                  },
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "is-flex is-mobile is-justify-content-space-between status-card-header",
                  },
                  [
                    _c("h2", { staticClass: "subtitle mb-0" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "component.dashboard.status.loggersStatus.loggers"
                          )
                        )
                      ),
                    ]),
                    _c("b-icon", {
                      staticClass: "is-size-5",
                      attrs: {
                        icon:
                          _vm.sources.length ==
                          _vm.sources.filter(
                            (x) => x.logger && x.logger.isOnline === true
                          ).length
                            ? "check-circle"
                            : "exclamation-triangle",
                        type:
                          _vm.sources.length ==
                          _vm.sources.filter(
                            (x) => x.logger && x.logger.isOnline === true
                          ).length
                            ? "is-success"
                            : "is-primary",
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "status-card-body" }, [
                  _vm.sources.length > 0
                    ? _c("div", [
                        _vm.sources.length ==
                        _vm.sources.filter(
                          (x) => x.logger && x.logger.isOnline === true
                        ).length
                          ? _c("h3", { staticClass: "subtitle is-5 mb-1" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("component.dashboard.status.online")
                                  ) +
                                  " "
                              ),
                            ])
                          : _c("h3", { staticClass: "subtitle is-5 mb-1" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "component.dashboard.status.offline",
                                      {
                                        count:
                                          _vm.sources.length -
                                          _vm.sources.filter(
                                            (x) =>
                                              x.logger &&
                                              x.logger.isOnline === true
                                          ).length,
                                      }
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "component.dashboard.status.outOfLoggers",
                                { count: _vm.sources.length }
                              )
                            )
                          ),
                        ]),
                      ])
                    : _c("div", [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "component.dashboard.status.loggersStatus.noLoggers"
                              )
                            )
                          ),
                        ]),
                      ]),
                ]),
              ]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "column is-one-third-tablet is-2-widescreen" },
          [
            _c(
              "div",
              {
                staticClass: "box is-full-height p-0",
                class: _vm.outOfBoundsCount > 0 ? "border-warning" : "",
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "is-flex is-mobile is-justify-content-space-between status-card-header",
                  },
                  [
                    _c("h2", { staticClass: "subtitle mb-0" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "component.dashboard.status.measurementsStatus.measurements"
                          )
                        )
                      ),
                    ]),
                    _c("b-icon", {
                      staticClass: "is-size-5",
                      attrs: {
                        icon:
                          _vm.outOfBoundsCount > 0
                            ? "exclamation-triangle"
                            : "check-circle",
                        type:
                          _vm.outOfBoundsCount > 0
                            ? "is-primary"
                            : "is-success",
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "status-card-body" }, [
                  _vm.measuredEventsCount > 0
                    ? _c("div", [
                        _vm.outOfBoundsCount == 0
                          ? _c("h3", { staticClass: "subtitle is-5 mb-1" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "component.dashboard.status.measurementsStatus.withinBounds"
                                    )
                                  ) +
                                  " "
                              ),
                            ])
                          : _c("h3", { staticClass: "subtitle is-5 mb-1" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "component.dashboard.status.measurementsStatus.outOfBounds",
                                      { count: _vm.outOfBoundsCount }
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                        _c(
                          "div",
                          {
                            staticClass:
                              "is-flex is-mobile is-justify-content-space-between",
                          },
                          [
                            _c("p", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "component.dashboard.status.measurementsStatus.outOfMeasurements",
                                      { count: _vm.measuredEventsCount }
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                            _c("div", { staticClass: "is-flex is-mobile" }, [
                              _vm.outOfBoundsTempCount > 0
                                ? _c(
                                    "div",
                                    { staticClass: "is-flex is-mobile" },
                                    [
                                      _c("b-icon", {
                                        staticClass: "pt-2",
                                        attrs: {
                                          size: "is-small",
                                          icon: "thermometer-half",
                                          type: "is-primary",
                                        },
                                      }),
                                      _c("p", [
                                        _vm._v(
                                          _vm._s(_vm.outOfBoundsTempCount)
                                        ),
                                      ]),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.outOfBoundsHumidityCount > 0
                                ? _c(
                                    "div",
                                    { staticClass: "is-flex is-mobile ml-1" },
                                    [
                                      _c("b-icon", {
                                        staticClass: "pt-2",
                                        attrs: {
                                          size: "is-small",
                                          icon: "tint",
                                          type: "is-primary",
                                        },
                                      }),
                                      _c("p", [
                                        _vm._v(
                                          _vm._s(_vm.outOfBoundsHumidityCount)
                                        ),
                                      ]),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]),
                          ]
                        ),
                      ])
                    : _c("div", [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "component.dashboard.status.measurementsStatus.noMeasurements"
                              )
                            )
                          ),
                        ]),
                      ]),
                ]),
              ]
            ),
          ]
        ),
        _c(
          "div",
          { staticClass: "column is-one-third-tablet is-2-widescreen" },
          [
            _c(
              "router-link",
              {
                staticClass: "box is-full-height p-0",
                class:
                  _vm.withBatteryUnder15.length > 0 ||
                  _vm.withBatteryUnder50.length > 0 ||
                  _vm.withBatteryUnknown.length > 0
                    ? "border-warning"
                    : "",
                attrs: {
                  to: {
                    name: "configuration_loggers",
                    params: {
                      lang: _vm.$route.params.lang,
                      departmentId: _vm.$route.params.departmentId,
                    },
                  },
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "is-flex is-mobile is-justify-content-space-between status-card-header",
                  },
                  [
                    _c("h2", { staticClass: "subtitle mb-0" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "component.dashboard.status.batteryStatus.battery"
                          )
                        )
                      ),
                    ]),
                    _c("b-icon", {
                      staticClass: "is-size-5",
                      attrs: {
                        icon:
                          _vm.withBatteryUnder15.length > 0 ||
                          _vm.withBatteryUnder50.length > 0 ||
                          _vm.withBatteryUnknown.length > 0
                            ? "exclamation-triangle"
                            : "check-circle",
                        type:
                          _vm.withBatteryUnder15.length > 0 ||
                          _vm.withBatteryUnder50.length > 0 ||
                          _vm.withBatteryUnknown.length > 0
                            ? "is-primary"
                            : "is-success",
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "status-card-body" }, [
                  _vm.sources.length > 0
                    ? _c("div", [
                        _c(
                          "div",
                          {
                            staticClass:
                              "is-flex is-mobile is-justify-content-space-between",
                          },
                          [
                            _vm.withBatteryUnder15.length > 0
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "b-tooltip",
                                      {
                                        attrs: {
                                          type: "is-dark",
                                          label: _vm.$t(
                                            "component.dashboard.status.batteryStatus.criticalTooltip"
                                          ),
                                          position: "is-right",
                                        },
                                      },
                                      [
                                        _c("b-icon", {
                                          staticClass: "is-size-5",
                                          attrs: {
                                            icon: "battery-empty",
                                            type: "is-primary",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c("p", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "component.dashboard.status.batteryStatus.critical",
                                              {
                                                count:
                                                  _vm.withBatteryUnder15.length,
                                              }
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.withBatteryUnder50.length > 0
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "b-tooltip",
                                      {
                                        attrs: {
                                          type: "is-dark",
                                          label: _vm.$t(
                                            "component.dashboard.status.batteryStatus.lowTooltip"
                                          ),
                                        },
                                      },
                                      [
                                        _c("b-icon", {
                                          staticClass: "is-size-5",
                                          attrs: {
                                            icon: "battery-quarter",
                                            type: "is-info",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c("p", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "component.dashboard.status.batteryStatus.low",
                                              {
                                                count:
                                                  _vm.withBatteryUnder50.length,
                                              }
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.withBatteryAbove50.length > 0
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "b-tooltip",
                                      {
                                        attrs: {
                                          type: "is-dark",
                                          label: _vm.$t(
                                            "component.dashboard.status.batteryStatus.okTooltip"
                                          ),
                                          position: "is-left",
                                        },
                                      },
                                      [
                                        _c("b-icon", {
                                          staticClass: "is-size-5",
                                          attrs: {
                                            icon: "battery-full",
                                            type: "is-success",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c("p", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "component.dashboard.status.batteryStatus.ok",
                                              {
                                                count:
                                                  _vm.withBatteryAbove50.length,
                                              }
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.withBatteryUnknown.length > 0
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "b-tooltip",
                                      {
                                        attrs: {
                                          type: "is-dark",
                                          label: _vm.$t(
                                            "component.dashboard.status.batteryStatus.unknownTooltip"
                                          ),
                                          position: "is-left",
                                        },
                                      },
                                      [
                                        _c("b-icon", {
                                          staticClass: "is-size-5",
                                          attrs: {
                                            icon: "battery-empty",
                                            type: "is-grey",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c("p", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "component.dashboard.status.batteryStatus.unknown",
                                              {
                                                count:
                                                  _vm.withBatteryUnknown.length,
                                              }
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        ),
                        _c("p", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "component.dashboard.status.outOfLoggers",
                                  { count: _vm.sources.length }
                                )
                              ) +
                              " "
                          ),
                        ]),
                      ])
                    : _c("div", [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "component.dashboard.status.loggersStatus.noLoggers"
                              )
                            )
                          ),
                        ]),
                      ]),
                ]),
              ]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "column is-one-third-tablet is-2-widescreen" },
          [
            _c(
              "router-link",
              {
                staticClass: "box is-full-height p-0",
                class:
                  _vm.companyLoggerExpirationList.length !==
                  _vm.companyLoggerExpirationList.filter(
                    (x) => x.logger && x.logger.calibration.status.id === 1
                  ).length
                    ? "border-warning"
                    : "",
                attrs: {
                  to: {
                    name: "configuration_loggers",
                    params: {
                      lang: _vm.$route.params.lang,
                      departmentId: _vm.$route.params.departmentId,
                    },
                  },
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "is-flex is-mobile is-justify-content-space-between status-card-header",
                  },
                  [
                    _c("h2", { staticClass: "subtitle mb-0" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "component.dashboard.status.calibrationStatus.calibration"
                          )
                        )
                      ),
                    ]),
                    _c("b-icon", {
                      staticClass: "is-size-5",
                      attrs: {
                        icon:
                          _vm.companyLoggerExpirationList.length ==
                          _vm.companyLoggerExpirationList.filter(
                            (x) =>
                              x.logger && x.logger.calibration.status.id === 1
                          ).length
                            ? "check-circle"
                            : "exclamation-triangle",
                        type:
                          _vm.companyLoggerExpirationList.length ==
                          _vm.companyLoggerExpirationList.filter(
                            (x) =>
                              x.logger && x.logger.calibration.status.id === 1
                          ).length
                            ? "is-success"
                            : "is-primary",
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "status-card-body" }, [
                  _vm.companyLoggerExpirationList.length > 0
                    ? _c("div", [
                        _vm.companyLoggerExpirationList.length ==
                        _vm.companyLoggerExpirationList.filter(
                          (x) =>
                            x.logger && x.logger.calibration.status.id === 1
                        ).length
                          ? _c("h3", { staticClass: "subtitle is-5 mb-1" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "component.dashboard.status.calibrationStatus.allCalibrated"
                                    )
                                  ) +
                                  " "
                              ),
                            ])
                          : _c("h3", { staticClass: "subtitle is-5 mb-1" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "component.dashboard.status.calibrationStatus.expired",
                                      {
                                        count:
                                          _vm.companyLoggerExpirationList
                                            .length -
                                          _vm.companyLoggerExpirationList.filter(
                                            (x) =>
                                              x.logger &&
                                              x.logger.calibration.status.id ===
                                                1
                                          ).length,
                                      }
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                        _c("p", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "component.dashboard.status.calibrationStatus.outOfLoggers",
                                  {
                                    count:
                                      _vm.companyLoggerExpirationList.length,
                                  }
                                )
                              ) +
                              " "
                          ),
                        ]),
                      ])
                    : _c("div", [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "component.dashboard.status.calibrationStatus.noLoggers"
                              )
                            )
                          ),
                        ]),
                      ]),
                ]),
              ]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "column is-one-third-tablet is-2-widescreen" },
          [
            _c(
              "router-link",
              {
                staticClass: "box is-full-height p-0",
                class: _vm.totalSize > 0 ? "border-warning" : "",
                attrs: {
                  to: {
                    name: "notifications",
                    params: {
                      lang: _vm.$route.params.lang,
                      departmentId: _vm.$route.params.departmentId,
                    },
                  },
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "is-flex is-mobile is-justify-content-space-between status-card-header",
                  },
                  [
                    _c("h2", { staticClass: "subtitle mb-0" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "component.dashboard.status.notificationsStatus.notifications"
                          )
                        )
                      ),
                    ]),
                  ]
                ),
                _c("div", { staticClass: "status-card-body" }, [
                  _c("h3", { staticClass: "subtitle is-5 mb-1" }, [
                    _vm.totalSize > 0
                      ? _c("span", [
                          _c("span", { staticClass: "has-text-success" }, [
                            _vm._v(_vm._s(_vm.totalSize)),
                          ]),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "component.dashboard.status.notificationsStatus.count"
                                )
                              ) +
                              " "
                          ),
                        ])
                      : _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "component.dashboard.status.notificationsStatus.noNotifications"
                                )
                              ) +
                              " "
                          ),
                        ]),
                  ]),
                  _c("p", { staticClass: "has-text-grey" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "component.dashboard.status.notificationsStatus.msg"
                          )
                        ) +
                        " "
                    ),
                  ]),
                ]),
              ]
            ),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }