var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm.isLoading
        ? _c("b-loading", {
            attrs: {
              "is-full-page": true,
              active: _vm.isLoading,
              "can-cancel": false,
            },
            on: {
              "update:active": function ($event) {
                _vm.isLoading = $event
              },
            },
          })
        : _vm._e(),
      _c(
        "div",
        {
          staticClass:
            "title-with-edit is-flex is-align-content-center is-justify-content-space-between",
        },
        [
          _c(
            "div",
            { staticClass: "is-flex" },
            [
              _c(
                "h2",
                {
                  staticClass:
                    "subtitle is-4 is-spaced has-text-centered-touch mb-1 mr-2",
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("component.settings.device.device_info")) +
                      " "
                  ),
                ]
              ),
              _vm.$ability.can(
                _vm.$permActions.MANAGE,
                _vm.$permSubjects.DEVICE
              )
                ? _c("b-button", {
                    class: _vm.isEditing && "has-text-primary",
                    attrs: { "icon-right": "pencil-alt" },
                    on: {
                      click: function ($event) {
                        return _vm.editDevice()
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm.$ability.can(_vm.$permActions.MANAGE, _vm.$permSubjects.LOGGER) &&
          _vm.$ability.can(_vm.$permActions.MANAGE, _vm.$permSubjects.DEVICE) &&
          _vm.$featuresManager.isAvailable(_vm.$features.LOGGERS_IN_COMPANY)
            ? _c(
                "b-button",
                {
                  attrs: { type: "is-primary" },
                  on: {
                    click: function ($event) {
                      return _vm.startScan()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("component.settings.device.start_scan")))]
              )
            : _vm._e(),
        ],
        1
      ),
      _c("div", { staticClass: "columns columns-info-form mt-3" }, [
        _c(
          "div",
          { staticClass: "column is-two-fifths" },
          [
            _c(
              "b-field",
              {
                staticClass: "field-wrap",
                attrs: {
                  horizontal: "",
                  label: _vm.$t("component.device.list.table.deviceName"),
                },
              },
              [_c("p", [_vm._v(" " + _vm._s(_vm.device.deviceName) + " ")])]
            ),
            _c(
              "b-field",
              {
                staticClass: "field-wrap",
                attrs: {
                  horizontal: "",
                  label: _vm.$t("component.device.list.table.macAddress"),
                },
              },
              [
                _c("p", [
                  _vm._v(" " + _vm._s(_vm.device.deviceMacAddress) + " "),
                ]),
              ]
            ),
            _c(
              "b-field",
              {
                staticClass: "field-wrap",
                attrs: {
                  horizontal: "",
                  label: _vm.$t("component.device.list.table.description"),
                },
              },
              [
                !_vm.isEditing
                  ? _c("p", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.newDeviceDescription ||
                              _vm.device.description ||
                              "-"
                          ) +
                          " "
                      ),
                    ])
                  : _c("b-input", {
                      attrs: {
                        name: _vm.$t("component.device.list.table.description"),
                      },
                      model: {
                        value: _vm.newDeviceDescription,
                        callback: function ($$v) {
                          _vm.newDeviceDescription = $$v
                        },
                        expression: "newDeviceDescription",
                      },
                    }),
              ],
              1
            ),
            _c(
              "b-field",
              {
                attrs: {
                  horizontal: "",
                  label: _vm.$t("component.device.list.table.registered"),
                },
              },
              [
                _vm.dateTimeManager
                  ? _c("p", [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("generals.at_day")) +
                          " " +
                          _vm._s(
                            _vm.dateTimeManager.formatTime(
                              new Date(_vm.device.registeredAt * 1000)
                            )
                          ) +
                          " " +
                          _vm._s(_vm.$t("generals.by")) +
                          " " +
                          _vm._s(_vm.device.registeredBy.fullName) +
                          " "
                      ),
                    ])
                  : _vm._e(),
              ]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "column" },
          [
            _c(
              "b-field",
              {
                attrs: {
                  horizontal: "",
                  label: _vm.$t("component.device.list.table.isOnline"),
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "is-flex is-align-items-center" },
                  [
                    _c("b-icon", {
                      staticClass: "is-size-7",
                      attrs: {
                        type:
                          _vm.device.isOnline == true
                            ? "is-success"
                            : "is-danger",
                        icon: "circle",
                      },
                    }),
                    _vm.device.isOnline == true
                      ? _c("span", [_vm._v(_vm._s(_vm.$t("generals.online")))])
                      : _c("span", [
                          _vm._v(_vm._s(_vm.$t("generals.offline"))),
                        ]),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "b-field",
              {
                attrs: {
                  horizontal: "",
                  label: _vm.$t(
                    "component.device.list.table.signal.wifisignal"
                  ),
                },
              },
              [
                _vm.device.isOnline == false
                  ? [
                      _c("div", { staticClass: "is-flex" }, [
                        _c("img", {
                          staticClass: "signal mr-2",
                          attrs: {
                            src: require("@/assets/img/not_connected.svg"),
                          },
                        }),
                        _c("p", { staticClass: "mr-2" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "component.device.list.table.signal.not_connected"
                                )
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                    ]
                  : [
                      _c(
                        "b-tooltip",
                        {
                          attrs: {
                            label: _vm.signalValue
                              ? _vm.signalValue.toString()
                              : "",
                            position: "is-right",
                            type: "is-light",
                            active:
                              _vm.signalValue !=
                              _vm.SignalStrengthValueBreakpoints.UNKNOWN,
                          },
                        },
                        [
                          _c(
                            "transition",
                            { attrs: { name: "fade", mode: "out-in" } },
                            [
                              _c(
                                "div",
                                { key: _vm.signalValue, staticClass: "pb-1" },
                                [
                                  _vm.signalValue ===
                                  _vm.SignalStrengthValueBreakpoints.UNKNOWN
                                    ? _c(
                                        "div",
                                        { staticClass: "is-flex" },
                                        [
                                          _c("b-icon", {
                                            staticClass: "signal mr-2",
                                            attrs: {
                                              pack: "fas",
                                              icon: "spinner",
                                              size: "is-small",
                                              "custom-class": "fa-spin",
                                            },
                                          }),
                                          _c("p", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "component.device.list.table.signal.fetching"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]),
                                        ],
                                        1
                                      )
                                    : _vm.signalValue >=
                                      _vm.SignalStrengthValueBreakpoints
                                        .EXCELLENT
                                    ? _c("div", { staticClass: "is-flex" }, [
                                        _c("img", {
                                          staticClass: "signal mr-2",
                                          attrs: {
                                            src: require("@/assets/img/excellent_signal.svg"),
                                          },
                                        }),
                                        _c("p", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "component.device.list.table.signal.excellent"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]),
                                      ])
                                    : _vm.signalValue <
                                        _vm.SignalStrengthValueBreakpoints
                                          .EXCELLENT &&
                                      _vm.signalValue >=
                                        _vm.SignalStrengthValueBreakpoints.GOOD
                                    ? _c("div", { staticClass: "is-flex" }, [
                                        _c("img", {
                                          staticClass: "signal mr-2",
                                          attrs: {
                                            src: require("@/assets/img/good_signal.svg"),
                                          },
                                        }),
                                        _c("p", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "component.device.list.table.signal.good"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]),
                                      ])
                                    : _vm.signalValue <
                                      _vm.SignalStrengthValueBreakpoints.GOOD
                                    ? _c("div", { staticClass: "is-flex" }, [
                                        _c("img", {
                                          staticClass: "signal mr-2",
                                          attrs: {
                                            src: require("@/assets/img/poor_signal.svg"),
                                          },
                                        }),
                                        _c("p", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "component.device.list.table.signal.poor"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
              ],
              2
            ),
            _c(
              "b-field",
              {
                attrs: {
                  horizontal: "",
                  label: _vm.$t("component.device.list.table.lastSeen"),
                },
              },
              [
                _vm.dateTimeManager
                  ? _c("p", { staticClass: "mr-2" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.dateTimeManager.formatTime(
                              new Date(_vm.device.lastSeen * 1000)
                            )
                          ) +
                          " "
                      ),
                    ])
                  : _vm._e(),
              ]
            ),
          ],
          1
        ),
      ]),
      _vm.hasChanges
        ? _c(
            "div",
            {
              staticClass:
                "has-text-centered is-size-4 has-text-primary has-text-weight-semibold",
            },
            [
              _c("p", [
                _vm._v(
                  _vm._s(_vm.$t("component.group.detail.table.unsaved_changes"))
                ),
              ]),
            ]
          )
        : _vm._e(),
      _vm.isEditing &&
      _vm.$ability.can(_vm.$permActions.MANAGE, _vm.$permSubjects.DEVICE)
        ? _c(
            "div",
            { staticClass: "has-text-centered mt-1" },
            [
              _c(
                "b-button",
                {
                  attrs: { type: "is-primary", disabled: !_vm.hasChanges },
                  on: {
                    click: function ($event) {
                      return _vm.saveChanges()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("generals.save")))]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.$ability.can(_vm.$permActions.MANAGE, _vm.$permSubjects.LOGGER) &&
      _vm.$ability.can(_vm.$permActions.MANAGE, _vm.$permSubjects.DEVICE)
        ? _c(
            "b-modal",
            {
              attrs: {
                active: _vm.isAddLoggersModalActive,
                "has-modal-card": "",
                onCancel: _vm.onModalClose,
                "full-screen": "",
              },
              on: {
                "update:active": function ($event) {
                  _vm.isAddLoggersModalActive = $event
                },
              },
            },
            [
              _c("AddLoggersModal", {
                attrs: {
                  deviceId: _vm.device.deviceId,
                  sourceList: _vm.sourceList,
                  assignedLoggers: _vm.activeLoggers,
                  inWizard: false,
                  dateTimeManager: _vm.dateTimeManager,
                },
                on: { "close-modal": _vm.onModalClose },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }