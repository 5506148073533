var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c(
      "div",
      { staticClass: "is-flex is-justify-content-center mx-3 mx-0-desktop" },
      [
        !_vm.isLoggedIn
          ? _c(
              "div",
              { staticClass: "login-form" },
              [
                _c("h2", { staticClass: "title is-2" }, [
                  _vm._v(_vm._s(_vm.$t("component.login.title"))),
                ]),
                _c(
                  "b-field",
                  { attrs: { grouped: "" } },
                  [
                    _c(
                      "b-field",
                      {
                        attrs: {
                          label: _vm.$t("component.login.loginName"),
                          type: {
                            "is-danger": _vm.errors.has(
                              _vm.$t("component.login.loginName")
                            ),
                          },
                          message: _vm.errors.first(
                            _vm.$t("component.login.loginName")
                          ),
                        },
                      },
                      [
                        _c("b-input", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          ref: "email",
                          attrs: {
                            name: _vm.$t("component.login.loginName"),
                            type: "text",
                            placeholder: _vm.$t("component.login.loginName"),
                          },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.submitCredentialsAsync.apply(
                                null,
                                arguments
                              )
                            },
                          },
                          model: {
                            value: _vm.username,
                            callback: function ($$v) {
                              _vm.username = $$v
                            },
                            expression: "username",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-field",
                      {
                        attrs: {
                          label: _vm.$t("generals.password"),
                          type: {
                            "is-danger": _vm.errors.has(
                              _vm.$t("generals.password")
                            ),
                          },
                          message: _vm.errors.first(
                            _vm.$t("generals.password")
                          ),
                        },
                      },
                      [
                        _c("b-input", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          attrs: {
                            name: _vm.$t("generals.password"),
                            type: "password",
                            "password-reveal": "",
                            placeholder: _vm.$t("generals.password"),
                          },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.submitCredentialsAsync.apply(
                                null,
                                arguments
                              )
                            },
                          },
                          model: {
                            value: _vm.password,
                            callback: function ($$v) {
                              _vm.password = $$v
                            },
                            expression: "password",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-button",
                  {
                    attrs: {
                      type: "is-primary",
                      disabled: _vm.loginButtonDisabled,
                      expanded: "",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.submitCredentialsAsync()
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("component.login.login")))]
                ),
                _c("div", { staticClass: "level mt-2 mb-4" }, [
                  _c(
                    "div",
                    { staticClass: "level-left" },
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "forgotPassword",
                              params: {
                                lang: _vm.$route.params.lang,
                              },
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("component.login.forgot")) + " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "level-right" }),
                ]),
              ],
              1
            )
          : _c(
              "router-link",
              {
                staticClass: "mb-3",
                attrs: {
                  to: {
                    name: "home",
                    params: {
                      lang: _vm.$route.params.lang,
                    },
                  },
                },
              },
              [
                _c("b-button", {
                  attrs: { type: "is-primary", label: _vm.$t("map.to_home") },
                }),
              ],
              1
            ),
      ],
      1
    ),
    _c("div", { staticClass: "tile is-ancestor" }, [
      _c("div", { staticClass: "tile is-vertical is-parent" }, [
        _c(
          "div",
          { staticClass: "box tile is-child" },
          [
            _c(
              "l-map",
              {
                ref: "smartCityMap",
                staticStyle: { height: "70vh", width: "100%" },
                attrs: {
                  zoom: _vm.zoom,
                  center: _vm.center,
                  options: _vm.mapOptions,
                },
                on: { "update:zoom": _vm.zoomUpdate },
              },
              [
                _c("l-tile-layer", {
                  attrs: { url: _vm.url, attribution: _vm.attribution },
                }),
                _vm._l(_vm.sensors, function (sensor) {
                  return _c(
                    "l-marker",
                    {
                      key: sensor.id,
                      attrs: {
                        "lat-lng": _vm.latLng(
                          sensor.location.latitude,
                          sensor.location.longitude
                        ),
                      },
                      on: {
                        click: function ($event) {
                          return _vm.onMarkerClicked(sensor)
                        },
                      },
                    },
                    [
                      _c(
                        "l-tooltip",
                        {
                          attrs: {
                            options: { permanent: true, interactive: true },
                          },
                        },
                        [
                          _c(
                            "div",
                            _vm._l(sensor.values, function (val, index) {
                              return _c(
                                "p",
                                { key: index },
                                [
                                  _c("b-icon", {
                                    attrs: {
                                      icon: _vm.EventType.getIcon(
                                        val.Type.Name
                                      ),
                                    },
                                  }),
                                  _vm._v(
                                    " " +
                                      _vm._s(val.Value) +
                                      _vm._s(val.Type.Unit) +
                                      " "
                                  ),
                                ],
                                1
                              )
                            }),
                            0
                          ),
                        ]
                      ),
                      _c(
                        "l-popup",
                        {
                          attrs: {
                            options: {
                              closeButton: true,
                              interactive: true,
                              minWidth: _vm.chartWidth,
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "is-flex is-flex-direction-column" },
                            [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "has-text-grey has-text-weight-bold mb-0 mt-0",
                                },
                                [_vm._v(_vm._s(sensor.name))]
                              ),
                              _c("div", [
                                _c(
                                  "p",
                                  { staticClass: "has-text-grey mb-0 mt-1" },
                                  [_vm._v(_vm._s(_vm.$t("map.statistics")))]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "is-flex is-justify-content-space-around",
                                  },
                                  [
                                    _c(
                                      "table",
                                      { staticClass: "table is-fullwidth" },
                                      [
                                        _c("thead", [
                                          _c("tr", [
                                            _c("th"),
                                            _c("th", [
                                              _c(
                                                "abbr",
                                                {
                                                  attrs: {
                                                    title: _vm.$t(
                                                      "map.table.latest_long"
                                                    ),
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "map.table.latest_short"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]),
                                            _c("th", [
                                              _c(
                                                "abbr",
                                                {
                                                  attrs: {
                                                    title:
                                                      _vm.$t(
                                                        "map.table.max_long"
                                                      ),
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "map.table.max_short"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]),
                                            _c("th", [
                                              _c(
                                                "abbr",
                                                {
                                                  attrs: {
                                                    title:
                                                      _vm.$t(
                                                        "map.table.min_long"
                                                      ),
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "map.table.min_short"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]),
                                            _c("th", [
                                              _c(
                                                "abbr",
                                                {
                                                  attrs: {
                                                    title:
                                                      _vm.$t(
                                                        "map.table.avg_long"
                                                      ),
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "map.table.avg_short"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]),
                                          ]),
                                        ]),
                                        _c(
                                          "tbody",
                                          _vm._l(
                                            sensor.values,
                                            function (val, index) {
                                              return _c(
                                                "tr",
                                                { key: index + "_row" },
                                                [
                                                  _c(
                                                    "th",
                                                    [
                                                      _c("b-icon", {
                                                        attrs: {
                                                          icon: _vm.EventType.getIcon(
                                                            val.Type.Name
                                                          ),
                                                        },
                                                      }),
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "services." +
                                                                val.Type.Name.toLowerCase()
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                    ],
                                                    1
                                                  ),
                                                  _c("td", [
                                                    _vm._v(
                                                      _vm._s(val.Value) +
                                                        _vm._s(val.Type.Unit)
                                                    ),
                                                  ]),
                                                  sensor.data[
                                                    val.Type.Name.toLowerCase()
                                                  ]
                                                    ? _c("td", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              sensor.data[
                                                                val.Type.Name.toLowerCase()
                                                              ].info.valueMax.toFixed(
                                                                2
                                                              )
                                                            ) +
                                                            " " +
                                                            _vm._s(
                                                              val.Type.Unit
                                                            ) +
                                                            " "
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                  sensor.data[
                                                    val.Type.Name.toLowerCase()
                                                  ]
                                                    ? _c("td", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              sensor.data[
                                                                val.Type.Name.toLowerCase()
                                                              ].info.valueMin.toFixed(
                                                                2
                                                              )
                                                            ) +
                                                            " " +
                                                            _vm._s(
                                                              val.Type.Unit
                                                            ) +
                                                            " "
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                  sensor.data[
                                                    val.Type.Name.toLowerCase()
                                                  ]
                                                    ? _c("td", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              sensor.data[
                                                                val.Type.Name.toLowerCase()
                                                              ].info.valueAvg.toFixed(
                                                                2
                                                              )
                                                            ) +
                                                            " " +
                                                            _vm._s(
                                                              val.Type.Unit
                                                            ) +
                                                            " "
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                              _c("div", [
                                _c(
                                  "p",
                                  { staticClass: "has-text-grey mb-1 mt-2" },
                                  [_vm._v(_vm._s(_vm.$t("map.chart")))]
                                ),
                                _c("div", {
                                  attrs: {
                                    id: _vm.createChartSelector(sensor),
                                  },
                                }),
                              ]),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                }),
              ],
              2
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }