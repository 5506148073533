var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c(
      "div",
      { staticClass: "section-header-level" },
      [
        _c("h2", { staticClass: "subtitle is-4" }, [
          _vm._v(" " + _vm._s(_vm.$t("system_settings.title")) + " "),
        ]),
        _c("b-button", {
          staticClass: "is-hidden-mobile",
          attrs: { type: "is-primary", label: _vm.$t("generals.add") },
          on: {
            click: function ($event) {
              return _vm.addNew()
            },
          },
        }),
        _c("b-button", {
          staticClass: "is-hidden-tablet",
          attrs: { type: "is-primary", "icon-left": "plus" },
          on: {
            click: function ($event) {
              return _vm.addNew()
            },
          },
        }),
      ],
      1
    ),
    _vm.isAdding == true
      ? _c("section", [
          _c("hr"),
          _c("h3", { staticClass: "subtitle is-5" }, [
            _vm._v(_vm._s(_vm.$t("system_settings.add_new_title"))),
          ]),
          _c(
            "div",
            {
              staticClass:
                "is-flex is-justify-content-flex-start is-flex-direction-row",
            },
            [
              _c(
                "b-field",
                {
                  staticClass: "is-flex-grow-2 mr-1",
                  attrs: {
                    label: _vm.$t("system_settings.properties.key"),
                    type: {
                      "is-danger": _vm.errors.has(
                        _vm.$t("system_settings.properties.key")
                      ),
                    },
                    message: _vm.errors.first(
                      _vm.$t("system_settings.properties.key")
                    ),
                    "label-position": "on-border",
                  },
                },
                [
                  _c("b-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    attrs: {
                      type: "textarea",
                      expanded: "",
                      name: _vm.$t("system_settings.properties.key"),
                    },
                    model: {
                      value: _vm.selectedFieldLabel,
                      callback: function ($$v) {
                        _vm.selectedFieldLabel = $$v
                      },
                      expression: "selectedFieldLabel",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-field",
                {
                  staticClass: "is-flex-grow-3",
                  attrs: {
                    label: _vm.$t("system_settings.properties.value"),
                    "label-position": "on-border",
                    type: {
                      "is-danger": _vm.errors.has(
                        _vm.$t("system_settings.properties.value")
                      ),
                    },
                    message: _vm.errors.first(
                      _vm.$t("system_settings.properties.value")
                    ),
                  },
                },
                [
                  _c("b-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    attrs: {
                      type: "textarea",
                      name: _vm.$t("system_settings.properties.value"),
                      expanded: "",
                    },
                    model: {
                      value: _vm.selectedFieldValue,
                      callback: function ($$v) {
                        _vm.selectedFieldValue = $$v
                      },
                      expression: "selectedFieldValue",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "buttons is-right" },
            [
              _c("b-button", {
                attrs: { type: "is-danger", label: _vm.$t("generals.close") },
                on: {
                  click: function ($event) {
                    return _vm.cancelAdding()
                  },
                },
              }),
              _c("b-button", {
                attrs: { type: "is-success", label: _vm.$t("generals.save") },
                on: {
                  click: function ($event) {
                    return _vm.saveNewSetting()
                  },
                },
              }),
            ],
            1
          ),
          _c("hr"),
        ])
      : _vm._e(),
    _c(
      "section",
      _vm._l(_vm.systemSettings, function (setting) {
        return _c(
          "div",
          { key: setting.SettingKey },
          [
            _c(
              "b-field",
              {
                staticClass: "field-wrap-mobile",
                attrs: { horizontal: "", label: setting.SettingKey },
              },
              [
                _vm.fieldEditing == setting.SettingKey
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "is-flex is-justify-content-flex-start is-flex-direction-row",
                      },
                      [
                        _c(
                          "b-field",
                          {
                            staticClass: "is-flex-grow-2 mr-1",
                            attrs: {
                              label: _vm.$t("system_settings.properties.key"),
                              "label-position": "on-border",
                            },
                          },
                          [
                            _c("b-input", {
                              attrs: {
                                type: "text",
                                name: `${setting.SettingKey}_label`,
                                expanded: "",
                              },
                              model: {
                                value: _vm.selectedFieldLabel,
                                callback: function ($$v) {
                                  _vm.selectedFieldLabel = $$v
                                },
                                expression: "selectedFieldLabel",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-field",
                          {
                            staticClass: "is-flex-grow-3 field-wrap",
                            attrs: {
                              label: _vm.$t("system_settings.properties.value"),
                              "label-position": "on-border",
                            },
                          },
                          [
                            _c("b-input", {
                              attrs: {
                                type: "textarea",
                                name: `${setting.SettingKey}_value`,
                                expanded: "",
                              },
                              model: {
                                value: _vm.selectedFieldValue,
                                callback: function ($$v) {
                                  _vm.selectedFieldValue = $$v
                                },
                                expression: "selectedFieldValue",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("b-button", {
                          staticClass: "ml-2",
                          attrs: { "icon-right": "times-circle" },
                          on: {
                            click: function ($event) {
                              return _vm.cancelEdit()
                            },
                          },
                        }),
                        _c("b-button", {
                          staticClass: "ml-2",
                          attrs: {
                            type: "is-primary",
                            label: _vm.$t("generals.save"),
                          },
                          on: {
                            click: function ($event) {
                              return _vm.saveEdit(setting.SettingKey)
                            },
                          },
                        }),
                      ],
                      1
                    )
                  : _c(
                      "div",
                      {
                        staticClass:
                          "is-flex is-flex-direction-row is-align-items-center",
                      },
                      [
                        _c("p", [_vm._v(" " + _vm._s(setting.Value) + " ")]),
                        _c("b-button", {
                          staticClass: "ml-2",
                          attrs: { "icon-right": "pencil-alt" },
                          on: {
                            click: function ($event) {
                              return _vm.editSettings(setting.SettingKey)
                            },
                          },
                        }),
                      ],
                      1
                    ),
              ]
            ),
          ],
          1
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }